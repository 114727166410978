import {
  QUERY_LOGGIN_APP,
  QUERY_GET_ME,
  QUERY_LOGIN_USER,
  QUERY_GET_APPS,
  QUERY_GET_APP,
  QUERY_GET_TRANSACTIONS,
  QUERY_GET_ACESSOSAPLICATIVO,
  QUERY_GET_ACESSOSAPLICATIVO_PENDENTE,
  MUTATION_CREATE_APP,
  MUTATION_UPDATE_APP,
  MUTATION_REMOVE_APP,
  MUTATION_CREATE_ACESSOAPLICATIVO,
  MUTATION_REMOVE_ACESSOAPLICATIVO,
  MUTATION_REVIEW_ACESSOAPLICATIVO,
  MUTATION_BLOCKUNBLOCK_APLICATIVO,
  MUTATION_DISABLE_APLICATIVO,
} from "helpers/graphql/queries";
import { simpleClient, authClient, client } from "helpers/graphql/client";

import { graphqlUrl } from "../../Config";

export const loginUser = (appToken, login, password) => {
  return client(graphqlUrl, appToken)
    .query({
      query: QUERY_LOGIN_USER,
      variables: { login, password },
    })
    .then((result) => {
      if (result.data.loginUser != undefined) {
        const { token } = result.data.loginUser;
        console.log("entrou");
        return token;
      }
      console.log("error aqui");
      return "";
    });
};

export const getProfileData = (appToken, userToken) => {
  return authClient(graphqlUrl, appToken, userToken).query({
    query: QUERY_GET_ME,
  });
};

export const getApps = (appToken, userToken, idpessoa) => {
  return authClient(graphqlUrl, appToken, userToken).query({
    query: QUERY_GET_APPS,
    variables: { idpessoa },
  });
};

export const getAppsAcessoPendente = (appToken, userToken) => {
  return authClient(graphqlUrl, appToken, userToken).query({
    query: QUERY_GET_ACESSOSAPLICATIVO_PENDENTE,
  });
};

export const getApp = (appToken, userToken, uuid) => {
  return authClient(graphqlUrl, appToken, userToken).query({
    query: QUERY_GET_APP,
    variables: { uuid },
  });
};

export const loginApp = (appId, appKey) => {
  return simpleClient(graphqlUrl).query({
    query: QUERY_LOGGIN_APP,
    variables: { appId, appKey },
  });
};

export const updateApp = (appToken, userToken, uuid, data) => {
  return authClient(graphqlUrl, appToken, userToken).mutate({
    mutation: MUTATION_UPDATE_APP,
    variables: { uuid, ...data },
  });
};

export const createApp = (appToken, userToken, data) => {
  return authClient(graphqlUrl, appToken, userToken).mutate({
    mutation: MUTATION_CREATE_APP,
    variables: { ...data },
  });
};

export const removeApp = (appToken, userToken, uuid) => {
  return authClient(graphqlUrl, appToken, userToken).mutate({
    mutation: MUTATION_REMOVE_APP,
    variables: { uuid },
  });
};

export const reviewAcessoAplicativo = (
  appToken,
  userToken,
  idacessoaplicativo,
  data
) => {
  return authClient(graphqlUrl, appToken, userToken).mutate({
    mutation: MUTATION_REVIEW_ACESSOAPLICATIVO,
    variables: { idacessoaplicativo, ...data },
  });
};

export const getTransactions = (appToken, userToken) => {
  return authClient(graphqlUrl, appToken, userToken).query({
    query: QUERY_GET_TRANSACTIONS,
  });
};

export const getAcessosAplicativo = (
  appToken,
  userToken,
  appId = null,
  appuid = null
) => {
  let variables;
  if (appId) variables = { appId };
  if (appuid) variables = { appuid };
  return authClient(graphqlUrl, appToken, userToken).query({
    query: QUERY_GET_ACESSOSAPLICATIVO,
    variables: variables,
  });
};
export const createAcessoAplicativo = (appToken, userToken, data) => {
  return authClient(graphqlUrl, appToken, userToken).mutate({
    mutation: MUTATION_CREATE_ACESSOAPLICATIVO,
    variables: { ...data },
  });
};

export const removeAcessoAplicativo = (
  appToken,
  userToken,
  idacessoaplicativo
) =>
  authClient(graphqlUrl, appToken, userToken).mutate({
    mutation: MUTATION_REMOVE_ACESSOAPLICATIVO,
    variables: { idacessoaplicativo },
  });

export const blockAplicativo = (appToken, userToken, uuid, data) =>
  authClient(graphqlUrl, appToken, userToken).mutate({
    mutation: MUTATION_BLOCKUNBLOCK_APLICATIVO,
    variables: { uuid, ...data },
  });

export const disableAplicativo = (appToken, userToken, uuid, data) =>
  authClient(graphqlUrl, appToken, userToken).mutate({
    mutation: MUTATION_DISABLE_APLICATIVO,
    variables: { uuid, ...data },
  });

import React from "react";

import { Card, CardHeader, CardBody, Row, Button, Alert } from "reactstrap";

const AlertAppDisabled = () => {
  return (
    <Alert color="warning">
      <strong>Aviso!</strong> Este aplicativo encontra-se desativado! Não será
      possível consultar as API's da UFVJM nem alterar suas configurações
      enquanto estiver desativado.
    </Alert>
  );
};

const ContentWrapper = ({ children, title, disabled = true, handleBack }) => {
  return (
    <div className="col">
      <Card className="shadow">
        {disabled && <AlertAppDisabled />}
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <div>
              <i className="ni ni-app"></i>
            </div>
            <div className="col">
              <h3 className="mb-0">{title}</h3>
            </div>
            <div className="col text-right">
              {handleBack && (
                <div>
                  <Button
                    color="primary"
                    size="md"
                    className="float-right"
                    onClick={() => handleBack()}
                    outline
                  >
                    Voltar
                  </Button>
                </div>
              )}
            </div>
          </Row>
        </CardHeader>
        <CardBody>{children}</CardBody>
      </Card>
    </div>
  );
};

export default ContentWrapper;

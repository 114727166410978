import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export const ModalDelete = ({
    children,
    isOpen,
    setModalShow,
    handleRemove
  }) => (
    <Modal size="lg" isOpen={isOpen} fade={false}>
      <ModalHeader toggle={() => setModalShow(!isOpen)}>
        Remover aplicativo
      </ModalHeader>
      <ModalBody>
        Tem certeza que deseja remover esse app? Uma vez removido o mesmo não
        poderá ser utilizado para integração com as API's da UFVJM
        <div className="text text-center text-muted text-warning">
          <strong>{children}</strong>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => setModalShow(!isOpen)}>
          Não
        </Button>
        <Button className="btn-outline-danger" onClick={handleRemove}>
          Sim
        </Button>
      </ModalFooter>
    </Modal>
  );
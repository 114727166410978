import React from "react";

// reactstrap components
import { Container } from "reactstrap";

const AdminHeader = () => {
  return (
    <React.Fragment>
      <div className="header bg-gradient-dark text-white pb-8 pt-5 pt-md-8">
        <Container fluid></Container>
      </div>
    </React.Fragment>
  );
};

export default AdminHeader;

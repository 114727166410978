import React, { useState } from "react";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import AppDetailForm from "./AppDetailForm";
import { ModalDelete } from "./ModalDelete";
import AppTransactions from "./AppTransactions";
import { BasicPage } from "../../../../components/Common/BasicPage";
import ContentWrapper from "../../../../components/Common/ContentWrapper";
import {
  getApp,
  updateApp,
  removeApp,
  disableAplicativo,
} from "../../../../services/Auth";
import { useAuth } from "../../../../context/Auth";
import { useMachine } from "@xstate/react";
import AppDetailMachine from "./app-detail-machine";
import { useHistory } from "react-router-dom";
import { useAlertContext } from "../../../../context/Alert";
import { appIsBlocked, appIsDisabled } from "../utils/UserApp";

// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

const AppDetailPage = () => {
  const [tabs, setTabs] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const { appToken, userToken, profile } = useAuth();
  const { id: idAplicativo } = useParams();
  const { setAlertMessage } = useAlertContext();
  const history = useHistory();

  const [current, send] = useMachine(AppDetailMachine, {
    services: {
      getAppData: async (context, event) => {
        const result = await getApp(appToken, userToken, idAplicativo);
        const { idsituacao } = result.data.sistemasAplicativo;
        if (appIsBlocked(idsituacao))
          return Promise.reject("Aplicativo bloqueado");
        return Promise.resolve(result);
      },
    },
  });

  const toggleNavs = (e, state, index) => {
    e.preventDefault();
    setTabs(index);
  };

  const handleRedirect = (path) => {
    history.push(path);
  };

  const handleUpdate = (uuid, data) => {
    const dataToSend = data;
    dataToSend.idpessoa = profile.idpessoa;
    updateApp(appToken, userToken, uuid, dataToSend)
      .then((response) => response.data)
      .then((data) => {
        setAlertMessage(
          `Aplicativo ${data.sistemasUpdateAplicativo.nome} atualizado com sucesso!`
        );
        send("UPDATED");
      });
  };

  const handleRemove = (uuid) => {
    removeApp(appToken, userToken, uuid)
      .then((response) => response.data.sistemasRemoveAplicativo)
      .then((data) => {
        console.log("delete", data);
        setAlertMessage(`Aplicativo ${data.nome} removido!`);
        handleRedirect("/");
      });
  };

  const handleChangeAppSituation = async (uuid, disabled) => {
    const data = { disabled, idpessoa: profile.idpessoa };
    const result = await disableAplicativo(appToken, userToken, uuid, data);
    const { sistemasDisableAplicativo } = result.data;
    const { idsituacao } = sistemasDisableAplicativo;
    setAlertMessage(
      `Aplicativo ${idsituacao == 5 ? "desativado" : "Ativado"} `
    );
    send("UPDATED");
  };

  let view = "";

  if (current.matches("appNotFound")) {
    view = (
      <ContentWrapper title="">
        <div className="nav-wrapper">
          <h4> Aplicativo não encontrado</h4>
        </div>
      </ContentWrapper>
    );
  }

  if (current.matches("updating")) {
    view = (
      <ContentWrapper
        title={current.context.app.nome}
        handleBack={() => handleRedirect("/")}
        disabled={appIsDisabled(current.context.app.idsituacao)}
      >
        <div>
          <ModalDelete
            isOpen={modalOpen}
            setModalShow={setModalOpen}
            handleRemove={() => handleRemove(current.context.app.uuid)}
          >
            {current.context.app.nome}
          </ModalDelete>
          <div className="nav-wrapper">
            <Nav
              className="nav-fill flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={tabs === 1}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabs === 1,
                  })}
                  onClick={(e) => toggleNavs(e, "tabs", 1)}
                  href="#"
                  role="tab"
                >
                  <i className="ni ni-cloud-upload-96 mr-2" />
                  Dados do Aplicativo
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabs === 2}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabs === 2,
                  })}
                  onClick={(e) => toggleNavs(e, "tabs", 2)}
                  href="#"
                  role="tab"
                >
                  <i className="ni ni-bell-55 mr-2" />
                  Permissões
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabs === 3}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: tabs === 3,
                  })}
                  onClick={(e) => toggleNavs(e, "tabs", 3)}
                  href="#"
                  role="tab"
                >
                  <i className="ni ni-calendar-grid-58 mr-2" />
                  Messages
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <Card className="shadow">
            <CardBody>
              <TabContent activeTab={"tabs" + tabs}>
                <TabPane tabId="tabs1">
                  <div className="description">
                    {current.matches("updating") && (
                      <AppDetailForm
                        uuid={current.context.app.uuid}
                        nome={current.context.app.nome}
                        idpessoa={current.context.app.idpessoa}
                        idsituacao={current.context.app.idsituacao}
                        descricao={current.context.app.descricao}
                        url={current.context.app.url}
                        client_id={current.context.app.client_id}
                        client_key={current.context.app.client_key}
                        // handleCancel={handleCancel}
                        handleUpdate={handleUpdate}
                        handleRemove={setModalOpen}
                        handleToggle={handleChangeAppSituation}
                      />
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="tabs2">
                  <div className="description">
                    {current.context.app.idaplicativo && (
                      <AppTransactions
                        idaplicativo={current.context.app.idaplicativo}
                      />
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="tabs3">
                  <p className="description">
                    Raw denim you probably haven't heard of them jean shorts
                    Austin. Nesciunt tofu stumptown aliqua, retro synth master
                    cleanse. Mustache cliche tempor, williamsburg carles vegan
                    helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher
                    synth.
                  </p>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </ContentWrapper>
    );
  }

  return <BasicPage>{view}</BasicPage>;
};

export default AppDetailPage;

import { gql } from "apollo-boost";

export const QUERY_LOGGIN_APP = gql`
  query logginApp($appId: String!, $appKey: String!) {
    loginApp(input: { appId: $appId, appKey: $appKey }) {
      token
    }
  }
`;

export const QUERY_LOGIN_USER = gql`
  query Login($login: String!, $password: String!) {
    loginUser(input: { login: $login, password: $password }) {
      token
    }
  }
`;

export const QUERY_GET_ME = gql`
  query me {
    me {
      nome
      email
      containstitucional
      idpessoa
      vinculos {
        tipoVinculo
        listaVinculos
      }
    }
  }
`;

export const QUERY_GET_APP = gql`
  query getApp($uuid: String) {
    sistemasAplicativo(uuid: $uuid) {
      idaplicativo
      idsituacao
      uuid
      descricao
      nome
      url
      client_id
      client_key
    }
  }
`;

export const QUERY_GET_APPS = gql`
  query getAplicativos($idpessoa: Int!) {
    sistemasAplicativos(idpessoa: $idpessoa, pagination: { first: 10 }) {
      edges {
        cursor
        node {
          uuid
          descricao
          nome
          client_id
          client_key
          url
          idsituacao
        }
      }
    }
  }
`;

export const MUTATION_CREATE_APP = gql`
  mutation sistemasCreateAplicativo(
    $nome: String!
    $descricao: String!
    $url: String!
    $idpessoa: Int!
  ) {
    sistemasCreateAplicativo(
      input: {
        nome: $nome
        descricao: $descricao
        url: $url
        idpessoa: $idpessoa
      }
    ) {
      uuid
      descricao
      nome
      url
      client_id
      client_key
    }
  }
`;

export const MUTATION_UPDATE_APP = gql`
  mutation sistemasUpdateApp(
    $uuid: String!
    $nome: String!
    $descricao: String!
    $url: String!
    $idpessoa: Int!
  ) {
    sistemasUpdateAplicativo(
      uuid: $uuid
      input: {
        nome: $nome
        descricao: $descricao
        url: $url
        idpessoa: $idpessoa
      }
    ) {
      descricao
      nome
      url
    }
  }
`;

export const MUTATION_REMOVE_APP = gql`
  mutation removeApp($uuid: String!) {
    sistemasRemoveAplicativo(uuid: $uuid) {
      idaplicativo
      uuid
      nome
    }
  }
`;

export const QUERY_GET_TRANSACTIONS = gql`
  query getTransactions {
    commonTransacoes(pagination: { first: 10 }, externo: true) {
      edges {
        node {
          idtrans
          transacao
          descricao
          tipo
          exigeautenticacao
          sistema {
            idsistema
            sistema
          }
        }
      }
    }
  }
`;

export const QUERY_GET_ACESSOSAPLICATIVO = gql`
  query getAcessosAplicativo($appId: Int, $appuid: String) {
    sistemasAcessosAplicativo(
      pagination: { first: 10 }
      idaplicativo: $appId
      appuid: $appuid
    ) {
      edges {
        node {
          idacessoaplicativo
          idtransacao
          idsituacao
          idaplicativo
          idpessoaavaliacao
          direito
          justificativa
          motivo
          datamotivo
          objTransacao {
            idtrans
            transacao
            descricao
            tipo
            exigeautenticacao
          }
        }
      }
    }
  }
`;

export const QUERY_GET_ACESSOSAPLICATIVO_PENDENTE = gql`
  query getAcessosAplicativoPendente {
    sistemasAplicativosAcessoPendente(pagination: { first: 100 }) {
      edges {
        node {
          idaplicativo
          uuid
          descricao
          nome
          url
          datacadastro
          client_id
          client_key
          idsituacao
          acessos(pagination: { first: 100 }) {
            edges {
              node {
                idacessoaplicativo
                idsituacao
                justificativa
                objTransacao {
                  idtrans
                  transacao
                  descricao
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MUTATION_CREATE_ACESSOAPLICATIVO = gql`
  mutation createSistemasAcessoAplicativo(
    $idAplicativo: Int!
    $idTransacao: Int!
    $justificativa: String
  ) {
    sistemasCreateAcessoAplicativo(
      input: {
        idtransacao: $idTransacao
        idaplicativo: $idAplicativo
        justificativa: $justificativa
      }
    ) {
      idacessoaplicativo
      idsituacao
      idtransacao
      idaplicativo
      idpessoaavaliacao
      justificativa
    }
  }
`;

export const MUTATION_REMOVE_ACESSOAPLICATIVO = gql`
  mutation removeAcessoAplicativo($idacessoaplicativo: Int!) {
    sistemasRemoveAcessoAplicativo(idacessoaplicativo: $idacessoaplicativo) {
      idacessoaplicativo
      removidoem
    }
  }
`;

export const MUTATION_REVIEW_ACESSOAPLICATIVO = gql`
  mutation reviewAcessoAplicativo(
    $idacessoaplicativo: Int!
    $idsituacao: Int!
    $idpessoaavaliacao: Int!
    $motivo: String
  ) {
    sistemasReviewAcessoAplicativo(
      idacessoaplicativo: $idacessoaplicativo
      input: {
        idsituacao: $idsituacao
        idpessoaavaliacao: $idpessoaavaliacao
        motivo: $motivo
      }
    ) {
      idacessoaplicativo
      idsituacao
      idpessoaavaliacao
      justificativa
      motivo
    }
  }
`;

export const MUTATION_BLOCKUNBLOCK_APLICATIVO = gql`
  mutation blockUnblockSistemasAplicativo(
    $uuid: String!
    $blocked: Boolean!
    $motivo: String
    $idpessoa: Int!
  ) {
    sistemasBlockAplicativo(
      uuid: $uuid
      input: {
        blocked: $blocked
        motivo: $motivo
        idpessoaavaliacao: $idpessoa
      }
    ) {
      idaplicativo
      uuid
      descricao
      nome
      url
      idsituacao
      motivo
      idpessoaavaliacao
      datamotivo
    }
  }
`;

export const MUTATION_DISABLE_APLICATIVO = gql`
  mutation disableSistemasAplicativo(
    $uuid: String!
    $disabled: Boolean!
    $idpessoa: Int!
  ) {
    sistemasDisableAplicativo(
      uuid: $uuid
      disabled: $disabled
      idpessoa: $idpessoa
    ) {
      idaplicativo
      uuid
      idsituacao
    }
  }
`;

import React from "react";
import { Card, Row, CardHeader, Button } from "reactstrap";

export const ContainerWrapper = ({ children, handleCreate, title }) => {
  return (
    <div className="col">
      <Card className="shadow p-3">
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <h3 className="mb-0">{title}</h3>
            <div className="col text-right">
              <Button
                color="primary"
                onClick={() => handleCreate(true)}
                size="lg"
              >
                <i className="fas fa-plus"></i> Adicionar
              </Button>
            </div>
          </Row>
        </CardHeader>
        <div>{children}</div>
      </Card>
    </div>
  );
};

import React, { createContext, useContext, useState, useMemo } from "react";

const AlertContext = createContext();

export const useAlertContext = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [message, setAlertMessage] = useState("");

  const context = useMemo(
    () => ({
      message,
      setAlertMessage,
    }),
    [message]
  );

  return (
    <AlertContext.Provider value={context}>{children} </AlertContext.Provider>
  );
};

import React, { useState, Fragment } from "react";
import { Form, Row, Button, Input } from "reactstrap";

const JustifyTransaction = ({ transaction, handleSave, handleCancel }) => {
  const [text, setText] = useState("");

  return (
    <Fragment>
      <h2>
        <strong>Transação: {transaction.transaction_name}</strong>
      </h2>
      <h4 className="text text-muted">
        Essa transação é uma transação restrita que necessita de uma justificativa para ser utilizada. Essa justificativa passará
        por uma avaliação pela equipe responsável que irá habilita-la ou não nesse aplicativo. 
      </h4>
      <Form>
        <Row>
          <Input
            id="exampleFormControlTextarea1"
            placeholder="Informe a justificativa para solicitação desta transação com no mínimo 50 caracteres."
            rows="3"
            type="textarea"
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
        </Row>
        <Row className="pt-4">
          <Button
            className="float-right"
            color="primary"
            disabled={text.length >= 50 ? false : true}
            size="md"
            type="button"
            onClick={() => handleSave(transaction.transaction_id, text)}
          >
            Salvar
          </Button>
          <Button
            className="btn-outline-danger float-right"
            size="md"
            type="button"
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </Row>
      </Form>
    </Fragment>
  );
};

export default JustifyTransaction;

import React, { useState } from "react";
import useFormMachine from "../../../../components/Common/hooks/use-form-machine";

// reactstrap components
import {
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Button,
  Alert
} from "reactstrap";

const AppForm = ({ handleCancel, handleCreate }) => {
  const initialFormState = {
    nome: "",
    descricao: "",
    url: "",
  };
  const [newApp, setNewApp] = useState(initialFormState);

  const [current, send] = useFormMachine({
    services: {
      submit: (context, event) => {
        handleSubmit(event.data);
      },
      finished: (context, event) => {
        console.log("finished", event);
      },
      validate: (context, event) => {
        //console.log(event.data);
        const { nome, descricao, url } = event.data;
        if (nome != "" && descricao != "" && url != "") {
          return Promise.resolve(event.data);
        }
        return Promise.reject("Preencha todos os dados corretamente.");
      }
    }
  });

  const handleInputChange = event => {
    const { name, value } = event.target;
    setNewApp({ ...newApp, [name]: value });
  };

  const handleSubmit = data => {
    handleCreate({ ...data }, { status: true });
  };

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        send("VALIDATE", { data: { ...newApp } });
      }}
    >
      <Row>
        <Col md={12}>
          {current.matches("error") ? (
            <Alert color="danger">{current.context.errorMsg}</Alert>
          ) : null}
          <FormGroup>
            <Label for="appName">Nome *</Label>
            <Input
              type="text"
              name="nome"
              id="appName"
              placeholder="Nome da aplicação"
              value={newApp.appName}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="appDescription">Descrição *</Label>
            <Input
              type="textarea"
              name="descricao"
              id="appDescription"
              placeholder="Descrição da aplicação"
              value={newApp.appDescription}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="appUrl">URL da aplicação *</Label>
            <Input
              type="text"
              name="url"
              id="appUrl"
              placeholder="URL da aplicação"
              value={newApp.appUrl}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup check>
        <Input type="checkbox" name="check" id="exampleCheck" />
        <Label for="exampleCheck" check>
          Check me out
        </Label>
      </FormGroup>
      <Button color="primary">Salvar</Button>
      <Button color="warning" outline onClick={() => handleCancel()}>
        Cancelar
      </Button>
    </Form>
  );
};

export default AppForm;

import React from "react";
import {
  AdminContentContainer,
  PaginationContentContainer,
} from "../Common/Containers";
import { ApplicationsTableList } from "../Common/ApplicationsTableList";

const AppsBlockedPage = () => {
  return (
    <AdminContentContainer>
      <PaginationContentContainer title="Aplicativos Bloqueados">
        <ApplicationsTableList
          aplicativos={[]}
          handleReview={() => console.log("olaaaa")}
        />
      </PaginationContentContainer>
    </AdminContentContainer>
  );
};

export default AppsBlockedPage;

import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import UserLayout from "layouts/User.jsx";
import { AuthProvider } from "./context/Auth";
import { AlertProvider } from "./context/Alert";

import { PrivateRoute } from "./helpers/PrivateRoute";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <PrivateRoute
          path="/admin"
          render={(props) => <AdminLayout {...props} />}
        />
        <PrivateRoute
          path="/user"
          render={(props) => <UserLayout {...props} />}
        />
        <Redirect exact from="/" to="/user/apps" />
      </Switch>
    </BrowserRouter>
  );
};

function App(props) {
  return (
    <AuthProvider>
      <AlertProvider>
        <Routes />
      </AlertProvider>
    </AuthProvider>
  );
}

export default App;

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../context/Auth";

const jwtDecode = require("jwt-decode");

const tokenExpired = (token) => {
  const { exp } = jwtDecode(token);
  var now = Math.round(Date.now() / 1000);
  const expired = exp < now;
  console.log("expired", expired);

  return expired;
};

const isAuthenticated = () => {
  const { appToken, userToken, profile, revokeUserAuth } = useAuth();

  if (!userToken || tokenExpired(userToken)) {
    revokeUserAuth();
    return false;
  }

  return appToken !== null && userToken !== null && profile !== null;
};

export const PrivateRoute = ({ render: Render, ...rest }) => {
  const isAuth = isAuthenticated();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? Render(props) : <Redirect to="/auth/login" />
      }
    />
  );
};

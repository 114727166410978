import { assign, Machine } from "xstate";

const AppDetailMachine = Machine({
  id: "app-datail-machine",
  initial: "gettingAppData",
  context: {
    app: {},
  },
  states: {
    gettingAppData: {
      invoke: {
        src: "getAppData",
        onDone: {
          target: "updating",
          actions: assign({
            app: (ctx, evt) => evt.data.data.sistemasAplicativo,
          }),
        },
        onError: {
          target: "appNotFound",
          actions: (ctx, evt) =>
            console.log("gettingAppData - ERROR", evt.data.message),
        },
      },
    },
    updating: {
      on: {
        UPDATED: "gettingAppData",
        REMOVING: "removing",
      },
    },
    removing: {},
    appNotFound: {},
  },
});

export default AppDetailMachine;

import React from 'react';


export const AppsNotFoundComponent = props => (
    <div className="container">
      <div className="row justify-content-center">
        <h3 className="text text-muted">
          Voçê ainda não possui nenhum aplicativo cadastrado.
        </h3>
      </div>
    </div>
  );
import React from "react";
import { Route, Switch } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import routes from "../routes.js";

const getBrandText = (path) => {
  for (let i = 0; i < routes.length; i++) {
    if (path.indexOf(routes[i].layout + routes[i].path) !== -1) {
      return routes[i].name;
    } else if (
      path.indexOf(
        routes[i].layout +
          routes[i].path.replace(":id", path.split("/").slice(-1).pop())
      ) !== -1
    ) {
      return routes[i].name;
    }
  }
  return "Brand";
};

const getRoutes = (routes) => {
  return routes.map((prop, key) => {
    if (prop.layout === "/user") {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    } else {
      return null;
    }
  });
};

const User = (props) => {
  return (
    <React.Fragment>
      {/* <Sidebar
        {...this.props}
        routes={userRoutes}
        logo={{
          innerLink: "/user/index",
          imgSrc: require("assets/img/brand/argon-react.png"),
          imgAlt: "..."
        }}
      /> */}
      <div className="main-content">
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />

        <Switch>{getRoutes(routes)}</Switch>
        {/* <Container fluid>
          <AdminFooter />
        </Container> */}
      </div>
    </React.Fragment>
  );
};

export default User;

import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
  } from "reactstrap";

export const NewAppModal = ({ isOpen, setModalShow, children }) => (
    <Modal
      size="lg"
      isOpen={isOpen}
    >
      <ModalHeader toggle={() => setModalShow(!isOpen)}>Novo aplicativo</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
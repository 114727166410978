import React from "react";
import Header from "components/Headers/Header.jsx";
import { Container, Row } from "reactstrap";
import { AlertComponent } from "../../components/Common/Alerts";

export const BasicPage = ({ children }) => {
  return (
    <React.Fragment>
      <AlertComponent />
      <Header />
      <Container className="mt--7 col-md-8" fluid>
        
        <Row>{children}</Row>
        {/* Dark table */}
      </Container>
    </React.Fragment>
  );
};

export const buildTogglesTransactionState = (
  transactions,
  activeTransactions
) => {
  const togglesStates = transactions.map((item, _) => {
    const {
      idtrans,
      transacao,
      descricao,
      exigeautenticacao,
      tipo,
    } = item.node;

    const acc = activeTransactions.find(
      (item) => item.node.idtransacao == idtrans
    );

    return {
      transaction_id: idtrans,
      transaction_name: transacao,
      description: descricao,
      justification: acc ? acc.node.justificativa : "",
      reason: acc ? acc.node.motivo : "",
      tipo,
      is_authenticated: exigeautenticacao,
      id_situacao: acc ? acc.node.idsituacao : null,
      acessoaplicativo_id: acc ? acc.node.idacessoaplicativo : null,
    };
  });

  return togglesStates;
};

import React from "react";
import AdminHeader from "components/Headers/AdminHeader";

// reactstrap components
import {
  Container,
  Card,
  CardHeader,
  CardFooter,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";

export const PaginationContentContainer = ({
  title,
  children,
  handleBack,
}) => {
  return (
    <Row>
      <div className="col">
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">
              {title}
              {handleBack && (
                <Button
                  className="float-right"
                  color="primary"
                  size="md"
                  type="button"
                  onClick={() => handleBack()}
                >
                  Voltar
                </Button>
              )}
            </h3>
          </CardHeader>
          {children}
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className="disabled">
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className="active">
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    1
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    2 <span className="sr-only">(current)</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    3
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Card>
      </div>
    </Row>
  );
};

export const AdminContentContainer = ({ children }) => {
  return (
    <React.Fragment>
      <AdminHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        {children}
      </Container>
    </React.Fragment>
  );
};

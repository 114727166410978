/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.jsx";
import Profile from "views/examples/Profile.jsx";
import Maps from "views/examples/Maps.jsx";
import Register from "views/examples/Register.jsx";
import LoginPage from "./LoginPage";
import Tables from "views/examples/Tables.jsx";
import Clients from "views/admin/Clients.jsx";
import ReviewAppsPage from "./admin/components/ReviewAppsPage";
import { ReviewAccess } from "./admin/components/ReviewAppsPage/ReviewAccess";
import AppBlockedPage from "./admin/components/AppsBlockedPage";
import AppsPage from "./admin/components/AppsPage";
import DashboardPage from "./admin/components/DashboardPage";
import MyAppsPage from "./user/components/AppsPage";
import UserProfile from "./user/components/UserProfile";
import AppDatailPage from "./user/components/AppsPage/AppDetailPage";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/review/app/:id",
    name: "ReviewApp",
    component: ReviewAccess,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/apps",
    name: "Aplicativos",
    icon: "ni ni-app text-primary",
    layout: "/admin",
    sub: [
      {
        path: "/apps/review",
        name: "Review",
        component: ReviewAppsPage,
        layout: "/admin",
      },
      {
        path: "/apps/bloqued",
        name: "Bloqueados",
        component: AppBlockedPage,
        layout: "/admin",
      },
      {
        path: "/apps/all",
        name: "Todos",
        component: AppsPage,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/user",
  },
  {
    path: "/apps",
    name: "Portal de desenvolvedores da UFVJM",
    icon: "ni ni-planet text-blue",
    component: MyAppsPage,
    layout: "/user",
  },
  {
    path: "/app/:id",
    name: "Portal de desenvolvedores da UFVJM",
    icon: "ni ni-planet text-blue",
    component: AppDatailPage,
    layout: "/user",
  },
  {
    path: "/profile",
    name: "Portal de desenvolvedores da UFVJM",
    icon: "ni ni-planet text-blue",
    component: UserProfile,
    layout: "/user",
  },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin"
  // },

  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // }
];
export default routes;

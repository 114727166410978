import { Machine, assign } from "xstate";

const AppTransactionsMachine = Machine({
  id: "app-transactions-machine",
  initial: "browsing",
  context: {
    togglesData: {},
    appAccesses: [],
    transactions: [],
    errorMessage: "",
    currentTransaction: {},
  },
  states: {
    browsing: {
      on: { "": { target: "fetchingAllTransactions" } },
    },
    fetchingAllTransactions: {
      invoke: {
        src: "getTransactions",
        onDone: {
          target: "fetchingAppAccesses",
          actions: [
            assign({
              transactions: (context, event) => {
                return event.data.data.commonTransacoes.edges;
              },
            }),
          ],
        },
        onError: {
          target: "error",
          actions: assign({
            errorMessage: (context, event) => {
              console.log(event);
            },
          }),
        },
      },
    },
    fetchingAppAccesses: {
      invoke: {
        src: "getAppAccesses",
        onDone: {
          target: "buildingToggles",
          actions: [
            assign({
              appAccesses: (context, event) => {
                const data = event.data.data.sistemasAcessosAplicativo.edges;
                return data;
              },
            }),
          ],
        },
        onError: {
          target: "error",
          actions: assign({
            errorMessage: (context, event) => {
              console.log(event);
            },
          }),
        },
      },
    },
    buildingToggles: {
      invoke: {
        src: "loadTogglesParam",
        onDone: {
          target: "selecting",
          actions: assign({
            togglesData: (context, event) => {
              return event.data;
            },
          }),
        },
        onError: {
          target: "error",
          actions: assign({
            errorMessage: (context, event) => {
              console.log(event);
            },
          }),
        },
      },
    },
    selecting: {
      on: {
        BROWSING: "browsing",
        JUSTIFY: {
          target: "justifying",
          actions: assign({
            currentTransaction: (context, event) => {
              console.log("envent", event);
              const { transaction_id, transaction_name } = event;
              return {
                transaction_id,
                transaction_name,
              };
            },
          }),
        },
      },
    },
    justifying: {
      on: {
        BROWSING: "browsing",
      },
    },
    error: {
      type: "final",
    },
    complete: {
      type: "final",
    },
  },
});

export default AppTransactionsMachine;

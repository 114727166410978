import React from "react";
import { useMachine } from "@xstate/react";
import AppTransactionsMachine from "./app-transactions-machine";
import {
  getTransactions,
  getAcessosAplicativo,
  createAcessoAplicativo,
  removeAcessoAplicativo,
} from "../../../../../services/Auth";
import { useAuth } from "../../../../../context/Auth";
import { buildTogglesTransactionState } from "./helpers/Transactions";
import TransactionsGrid from "./components/TransactionsGrid";
import JustifyTransaction from "./components/JustifyTransaction";

const AppTransactions = ({ idaplicativo }) => {
  const { appToken, userToken } = useAuth();

  const [current, send] = useMachine(AppTransactionsMachine, {
    services: {
      getAppData: (context, event) => {},
      getTransactions: (context, event) => getTransactions(appToken, userToken),
      getAppAccesses: (context, event) => {
        return getAcessosAplicativo(appToken, userToken, idaplicativo);
      },
      loadTogglesParam: (context, _) => {
        const data = buildTogglesTransactionState(
          context.transactions,
          context.appAccesses
        );
        return Promise.resolve(data);
      },
    },
  });

  const handleCreateAcessoAplicativo = (
    transaction_id,
    justificativa = null
  ) => {
    createAcessoAplicativo(appToken, userToken, {
      idAplicativo: idaplicativo,
      idTransacao: parseInt(transaction_id),
      justificativa,
    })
      .then((response) => {
        console.log("createAcessoAplicativo", response.data);
        send("BROWSING");
      })
      .catch((error) => console.log("createAcessoAplicativo:error", error));
  };

  const handleRemoveAcessoAplicativo = (idacessoaplicativo) => {
    removeAcessoAplicativo(appToken, userToken, idacessoaplicativo)
      .then((response) => {
        console.log("removeAcessoAplicativo", response.data);
        send("BROWSING");
      })
      .catch((error) => console.log("removeAcessoAplicativo:error", error));
  };

  const handleToggle = (
    idacessoaplicativo,
    is_checked,
    transaction_id,
    transaction_name,
    tipo
  ) => {
    if (!is_checked) {
      console.log("tipo", transaction_id, tipo, is_checked);
      if (String(tipo).toLocaleLowerCase() === "restrito") {
        send({ type: "JUSTIFY", transaction_id, transaction_name });
      } else {
        handleCreateAcessoAplicativo(transaction_id);
      }
    } else {
      handleRemoveAcessoAplicativo(idacessoaplicativo);
    }
  };

  return (
    <div className="container">
      {current.matches("selecting") && (
        <TransactionsGrid
          togglesData={current.context.togglesData}
          handleToggle={handleToggle}
        />
      )}
      {current.matches("justifying") && (
        <JustifyTransaction
          transaction={current.context.currentTransaction}
          handleSave={handleCreateAcessoAplicativo}
          handleCancel={() => send("BROWSING")}
        />
      )}
    </div>
  );
};

export default AppTransactions;

import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap";
import { useAlertContext } from "../../../context/Alert";
import "./style.css";

export const AlertComponent = (props) => {
  const [visible, setVisible] = useState(true);
  const { message, setAlertMessage } = useAlertContext();

  const onDismiss = () => {
    setAlertMessage(null);
    setVisible(false);
  };

  useEffect(() => {
    if (message && visible) {
      setTimeout(() => {
        onDismiss();
      }, 5000);
    }
  }, []);

  return (
    message && (
      <Alert
        color="success"
        isOpen={visible}
        toggle={onDismiss}
        className="message-top-alert"
      >
        <span className="alert-inner--icon">
          <i className="ni ni-like-2" />
        </span>
        <span className="alert-inner--text">
          <strong>Warning!</strong> {message}
        </span>
      </Alert>
    )
  );
};

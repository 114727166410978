import ApolloClient from "apollo-boost";


export const simpleClient = uri =>
  new ApolloClient({
    uri: uri
  });

export const client = (uri, appToken) => {
  return new ApolloClient({
    uri: uri,
    request: operation => {
      operation.setContext({
        headers: {
          Application: `Bearer ${appToken}`
        }
      });
    }
  });
};

export const authClient = (uri, appToken, userToken) => {  
  return new ApolloClient({
    uri: uri,
    request: operation => {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${userToken}`,
          Application: `Bearer ${appToken}`
        }
      });
    }
  });
};
import React from 'react';
import Header from "components/Headers/Header.jsx";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Container,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";


const apps = [
  {
    id: 1,
    uid: "58746a5sd53as5d533a4s5d54fa",
    app_name: "Consulta Alunos",
    status: 1,
    user: "nilo.pereira",
    view_mode: "strict"
  },
  {
    id: 2,
    uid: "ar4fsdfe3er5sd53as5d533a4s5d54fa",
    app_name: "Consulta Notas",
    status: 1,
    user: "tiago.borges",
    view_mode: "strict"
  }
];

const TableRow = ({ app }) => {
  console.log(app);
  return(
    <React.Fragment>
      <tr>
        <th scope="row">
          <Media className="align-items-center">
            <a
              className="avatar rounded-circle mr-3"
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              <img
                alt="..."
                src={require("assets/img/theme/bootstrap.jpg")}
              />
            </a>
            <Media>
              <span className="mb-0 text-sm">{app.app_name}</span>
            </Media>
          </Media>
        </th>
        <td>{app.uid}</td>
        <td>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-warning" />
            {app.status}
          </Badge>
        </td>
        <td>
          <div className="avatar-group">
            <a
              className="avatar avatar-sm"
              href="#pablo"
              id="tooltip742438047"
              onClick={e => e.preventDefault()}
            >
              <img
                alt="..."
                className="rounded-circle"
                src={require("assets/img/theme/team-1-800x800.jpg")}
              />
            </a>
            <UncontrolledTooltip
              delay={0}
              target="tooltip742438047"
            >
              {app.user}
            </UncontrolledTooltip>            
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <span className="mr-2">60%</span>
            <div>
              <Progress
                max="100"
                value="60"
                barClassName="bg-danger"
              />
            </div>
          </div>
        </td>
        <td className="text-right">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color=""
              onClick={e => e.preventDefault()}
            >
              <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                Action
                            </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                Another action
                            </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                Something else here
                            </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    </React.Fragment>
  )
};



const Clients = () => (
  <React.Fragment>

    <Header />
    {/* Page content */}
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <h3 className="mb-0">Aplicações Registradas</h3>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">App Name</th>
                  <th scope="col">App ID</th>
                  <th scope="col">Status</th>
                  <th scope="col">User</th>
                  <th scope="col">View Mode</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {apps.map((app) => <TableRow key={app.id} app={app} />)}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className="disabled">
                    <PaginationLink
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className="active">
                    <PaginationLink
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      1
                        </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      2 <span className="sr-only">(current)</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      3
                        </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
          </Card>
        </div>
      </Row>
      {/* Dark table */}
    </Container>
  </React.Fragment>

);

export default Clients;
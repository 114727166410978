import React from "react";
import { Table } from "reactstrap";
import TransactionItem from "../TransactionItem";

const TransactionsGrid = ({ togglesData, handleToggle }) => {
  return (
    <div className="">
      <h6 className="heading-small text-muted">Permissões</h6>
      <hr className="mt-0" />
      <p className="text text-muted">
        As permissões habilitam o aplicativo para consumir recursos da UFVJM
      </p>
      <Table className="align-items-left" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Permissão</th>
            <th scope="col">Autenticação do usuário</th>
            <th scope="col">Restrito</th>
            <th scope="col">status</th>
            <th scope="col">Ação</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {togglesData.map((item, index) => (
            <TransactionItem
              key={index}
              handleToggle={handleToggle}
              {...item}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TransactionsGrid;

import React, { useState, useEffect, useReducer } from "react";
import LoginForm from "./LoginForm";
import { useAuth } from "../context/Auth";
import { Redirect } from "react-router-dom";
import { getProfileData } from "services/Auth";

import { Profile, getProfile } from "services/Profile";

// reactstrap components
import { Card, CardBody, Row, Col, Alert } from "reactstrap";

const routerMap = { admin: "/admin/apps/review", user: "/user/apps" };

const LoginPage = () => {
  const { setUserToken, setProfileData, appToken, userToken } = useAuth();
  const [error, setError] = useState(false);
  const [userType, setUserType] = useState("user");
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLoginError = () => setError(true);

  const handleProfileError = (error) => {
    console.log("profile error", error);
    setError(true);
  };

  const handleUserLogin = (token) => {
    if (token) {
      setUserToken(token);
    } else {
      setError(true);
    }
  };

  const fetchAndSaveProfile = async () => {
    const { isAdmin, me } = await getProfile(appToken, userToken);
    setProfileData(me);
    Profile.save(me);
    setUserType(isAdmin ? "admin" : "user");
    setLoggedIn(true);
  };

  useEffect(() => {
    if (userToken) {
      fetchAndSaveProfile();
    }
  }, [userToken]);

  if (loggedIn) {
    return <Redirect to={routerMap[userType]} />;
  }

  return (
    <React.Fragment>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {error && (
              <Alert color="danger">
                Falha no login. Verifique suas credênciais de acesso.
              </Alert>
            )}
            <div className="text-center text-muted mb-4">
              <small>Acesse com as suas credenciais</small>
            </div>
            <LoginForm
              appToken={appToken}
              handleUserLogin={handleUserLogin}
              handleLoginError={handleLoginError}
            />
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              <small>Esqueçeu a sua senha?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              <small>Criar conta</small>
            </a>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default LoginPage;

import React, { useReducer, useState, useEffect } from "react";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormFeedback,
  FormText,
} from "reactstrap";

import { loginUser } from "services/Auth";
import { useForm } from "components/Common/hooks/useForm";

const validade = ({ login, password }) => {
  let errors = {};
  if (!login) {
    errors.login = "Por favor informe o seu login.";
  }

  if (!password) {
    errors.password = "Por favor informe a sua senha.";
  }
  return errors;
};

const LoginForm = ({ appToken, handleUserLogin, handleLoginError }) => {
  const [requestError, setRequestError] = useState("");
  const [submit, setSubmit] = useState(false);

  const sendFormData = () => {
    if (values) {
      const result = loginUser(appToken, values.login, values.password);
      result
        .then((response) => handleUserLogin(response))
        .catch((error) => handleLoginError(error));
    }
  };

  const { values, handleChange, handleSubmit, errors } = useForm(
    sendFormData,
    validade
  );

  return (
    <Form role="form" onSubmit={handleSubmit}>
      <FormGroup className="mb-3">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Login"
            name="login"
            type="text"
            value={values.login || ""}
            onChange={handleChange}
            invalid={errors.login ? true : false}
          />
          <FormFeedback>{errors.login}</FormFeedback>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Password"
            name="password"
            type="password"
            value={values.password || ""}
            onChange={handleChange}
            invalid={errors.password ? true : false}
          />
          <FormFeedback>{errors.password}</FormFeedback>
        </InputGroup>
      </FormGroup>
      <div className="custom-control custom-control-alternative custom-checkbox">
        <input
          className="custom-control-input"
          id="customCheckLogin"
          type="checkbox"
        />
        <label className="custom-control-label" htmlFor=" customCheckLogin">
          <span className="text-muted">Remember me</span>
        </label>
      </div>
      <div className="text-center">
        <Button
          className="my-4"
          color="primary"
          type="submit"
          disabled={values.isValid}
          block
        >
          Acessar
        </Button>
      </div>
    </Form>
  );
};

export default LoginForm;

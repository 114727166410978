var env = process.env.NODE_ENV;

if(env === 'docker'){
    var appId = process.env.APP_ID;
    var appKey = process.env.APP_KEY;
    var graphqlUrl = process.env.GRAPHQL_URL;
} else {
    var appId = 'ed8cc4d3-a228-4964-a860-939a9c49db7d';
    var appKey = 'cb79ce4a28e5d1b4bd07a51ea8cbd124944be5c5968101d3c658779638939aa7';
    var graphqlUrl = 'https://micro-teste.dds.ufvjm.edu.br/';
}

module.exports = {
    graphqlUrl,
    appId,
    appKey,
    env
};
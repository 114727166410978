import React, { useState } from "react";
import {
  Row,
  Col,
  UncontrolledCollapse,
  Card,
  CardBody,
  CardText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { ModalInformation } from "../../../../../../../components/Common/Modal";

const approved = 1;
const blocked = 2;
const pending = 3;
const RESTRICT = "Restrito";

const mapStatuses = (id_situacao) => {
  let status = {};
  switch (id_situacao) {
    case approved:
      status = { status: "Aprovado", badge: "badge-success" };
      break;
    case pending:
      status = { status: "Pendente", badge: "badge-warning" };
      break;
    case blocked:
      status = { status: "Negado", badge: "badge-danger" };
      break;
    default:
      status = { status: "Não Selecionado", badge: "badge-secondary" };
      break;
  }

  return status;
};

const checkStatus = (id_situacao, status) =>
  id_situacao && id_situacao === status ? true : false;

const isApproved = (id_situacao) => {
  return checkStatus(id_situacao, approved);
};

const isPending = (id_situacao) => {
  return checkStatus(id_situacao, pending);
};

const isBlocked = (id_situacao) => {
  return checkStatus(id_situacao, blocked);
};

const isRestrict = (tipo) => tipo.toLowerCase() === RESTRICT.toLowerCase();

const TransactionItem = ({
  index,
  handleToggle,
  acessoaplicativo_id,
  transaction_id,
  transaction_name,
  is_authenticated,
  description,
  justification,
  reason,
  tipo,
  id_situacao,
}) => {
  const [detail, setDetail] = useState(false);
  const status = mapStatuses(id_situacao);

  return (
    <React.Fragment>
      <ModalInformation
        title="Detalhes"
        modal={detail}
        toggle={() => setDetail(!detail)}
      >
        <span>Justificativa:</span>
        <Card>
          <CardBody>{justification}</CardBody>
        </Card>
        {isBlocked(id_situacao) && (
          <div>
            <span>
              A solicitação para esté recurso não foi autorizada pelo motivo:
            </span>
            <Card>
              <CardBody>{reason}</CardBody>
            </Card>
          </div>
        )}
      </ModalInformation>
      <tr key={index} className="border-bottom" id={`tr-${transaction_name}`}>
        <td>
          <span className="font-weight-bold">{transaction_name}</span>
          <div className="text text-muted">
            <small>{description}</small>
          </div>
        </td>
        <td className="text-center">
          <div>
            <small>{is_authenticated ? "SIM" : "NÃO"}</small>
          </div>
        </td>
        <td className="text-center">
          <div>
            <small>{isRestrict(tipo) ? "SIM" : "NÃO"}</small>
          </div>
        </td>
        <td>
          {status && (
            <div>
              <span className={`badge ${status.badge}`}>{status.status}</span>
            </div>
          )}
        </td>
        <td>
          <label className="custom-toggle">
            <input
              className="custom-control-input"
              checked={isApproved(id_situacao)}
              id={transaction_id}
              type="checkbox"
              disabled={isPending(id_situacao) || isBlocked(id_situacao)}
              onChange={(e) =>
                handleToggle(
                  acessoaplicativo_id,
                  isApproved(id_situacao),
                  transaction_id,
                  transaction_name,
                  tipo
                )
              }
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </td>
        <td className="text-right">
          {justification && (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => setDetail(true)}>
                  Detalhes
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default TransactionItem;

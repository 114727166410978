import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Button,
} from "reactstrap";


export const ModalInformation = ({ title, modal, toggle, children }) => {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={modal}
      toggle={() => toggle()}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {title}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">{children}</div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          Fechar
        </Button>
      </div>
    </Modal>
  );
};

export const ModalDenyAccess = ({ modal, toggle, description, btnTitle, handleOk }) => {
  const [btnStatus, setBtnStatus] = useState(true);
  const [justify, setJustify] = useState("");

  const handleChange = (e) => {
    setJustify(e);
    e.length > 10 ? setBtnStatus(false) : setBtnStatus(true);
  };

  const handleButtonEvent = () => {
    handleOk(justify);
  };

  return (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
      isOpen={modal}
      toggle={toggle}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          Ponto de atenção.
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <h4 className="heading mt-4">Você precisar se atentar a isso!</h4>
          <p>
            {description}
          </p>

          <Form>
            <Input
              type="textarea"
              placeholder="Informe o motivo"
              onChange={(e) => handleChange(e.target.value)}
              value={justify}
            />
          </Form>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="btn-white"
          color="default"
          type="button"
          onClick={handleButtonEvent}
          disabled={btnStatus}
        >
          {btnTitle}
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          Fechar
        </Button>
      </div>
    </Modal>
  );
};

import { assign, Machine } from "xstate";

const AppsMachine = Machine({
  id: "apps-machine",
  context: {
    apps: [],
    uuid: null,
    app: {},
  },
  initial: "list",
  states: {
    list: {
      invoke: {
        src: "getApps",
        onDone: {
          actions: assign({
            apps: (context, event) => {
              const result = event.data.data.sistemasAplicativos.edges;
              console.log("result", result);
              return result;
            },
            error: () => null,
          }),
        },
        onError: {
          actions: assign({
            data: () => null,
            error: (context, event) => {
              const { graphQLErrors } = event.data;
              graphQLErrors.forEach((element) => {
                const { errors } = element.extensions.response.body;
                errors.forEach((item) => {
                  console.log("error-data", item.message);
                });
              });
              return event.data;
            },
          }),
        },
      },
      on: {
        CREATE: {
          target: "create",
        },
      },
    },
    create: {
      on: {
        CANCEL: {
          target: "list",
        },
      },
    },
  },
});

export default AppsMachine;

import React from "react";
import { Button, Modal } from "reactstrap";

const ModalAction = ({ children, modal, title, toggle, btnTitle, handleOk }) => {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={modal}
      toggle={() => toggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {title}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">{children}</div>
      <div className="modal-footer">
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          Fechar
        </Button>
        <Button color="primary" type="button" onClick={() => handleOk()}>
          {btnTitle}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalAction;

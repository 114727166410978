import React, { useReducer, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  getAppsAcessoPendente,
  blockAplicativo,
} from "../../../../src/services/Auth";
import { ApplicationsTableList } from "../Common/ApplicationsTableList";
import { ModalDenyAccess, ModalInformation } from "components/Common/Modal";
import {
  AdminContentContainer,
  PaginationContentContainer,
} from "../Common/Containers";

import { useAuth } from "../../../context/Auth";
import { useAlertContext } from "../../../context/Alert";
import ModalAction from "components/Common/Modal/ModalAction";

const viewReducer = (state, action) => {
  switch (action.type) {
    case "LIST":
      return { ...state, applications: action.payload };
    case "REVIEW":
      return { ...state, type: "REVIEW", currentApp: action.payload };
    case "SHOW_MODAL_BLOCK":
      return { ...state, modalBlock: true, currentApp: action.payload };
    case "HIDE_MODAL_BLOCK":
      return { ...state, modalBlock: !state.modalBlock };
    case "SHOW_MODAL_UNBLOCK":
      return { ...state, modalUnBlock: true, currentApp: action.payload };
    case "HIDE_MODAL_UNBLOCK":
      return { ...state, modalUnBlock: !state.modalUnBlock };
    default:
      return state;
  }
};

const initialState = {
  type: "LIST",
  applications: [],
  currentApp: null,
};

const ReviewAppsPage = () => {
  const [state, dispatch] = useReducer(viewReducer, initialState);
  const { appToken, userToken, profile } = useAuth();
  const { setAlertMessage } = useAlertContext();

  const handleDispatch = (type, payload) => {
    dispatch({ type, payload });
  };

  const handleReview = (payload) => {
    handleDispatch("REVIEW", payload);
  };

  const handleBlockAplication = async (message) => {
    const { uuid } = state.currentApp;
    const motivo = message;
    const blocked = true;
    const idpessoa = profile.idpessoa;
    const data = {
      idpessoa,
      blocked,
      motivo,
    };
    const result = await blockAplicativo(appToken, userToken, uuid, data);
    console.log("result", result);

    handleDispatch("HIDE_MODAL_BLOCK");
    loadPendingApps();
  };

  const handleShowModalUnblock = (payload) => {
    handleDispatch("SHOW_MODAL_UNBLOCK", payload);
  };

  const handleUnblockAplicativo = async (app) => {
    const { uuid } = state.currentApp;
    const blocked = false;
    const idpessoa = profile.idpessoa;
    const data = {
      idpessoa,
      blocked,
    };
    const result = await blockAplicativo(appToken, userToken, uuid, data);
    handleDispatch("HIDE_MODAL_UNBLOCK");
    loadPendingApps();
  };

  const handleShowModalBlock = (payload) => {
    handleDispatch("SHOW_MODAL_BLOCK", payload);
  };

  const loadPendingApps = () => {
    const result = getAppsAcessoPendente(appToken, userToken);
    result
      .then((response) => response.data)
      .then((data) => {
        const { edges } = data.sistemasAplicativosAcessoPendente;
        console.log("items", edges);
        handleDispatch("LIST", edges);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadPendingApps();
  }, []);

  return (
    <AdminContentContainer>
      <ModalAction
        modal={state.modalUnBlock}
        toggle={() => handleDispatch("HIDE_MODAL_UNBLOCK")}
        handleOk={handleUnblockAplicativo}
        description="Tem certeza que deseja desbloquear o aplicativo?"
        btnTitle="Desbloquear"
      >
        Tem certeza que deseja desbloquear o aplicativo?
      </ModalAction>

      <ModalDenyAccess
        modal={state.modalBlock}
        toggle={() => handleDispatch("HIDE_MODAL_BLOCK")}
        handleOk={handleBlockAplication}
        description="Informe o motivo do bloqueio do aplicativo"
        btnTitle="Bloquear"
      />
      {state.type === "LIST" && (
        <PaginationContentContainer title="Revisão de Aplicativos">
          {state.applications.length >= 1 ? (
            <ApplicationsTableList
              aplicativos={state.applications}
              handleReview={handleReview}
              handleBlock={handleShowModalBlock}
              handleUnblock={handleShowModalUnblock}
            />
          ) : (
            <p className="text text-center">Nenhum aplicativo para revisão</p>
          )}
        </PaginationContentContainer>
      )}
      {state.type === "REVIEW" && (
        <Redirect to={`/admin/review/app/${state.currentApp.uuid}`} />
      )}
    </AdminContentContainer>
  );
};

export default ReviewAppsPage;

import React from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardDeck,
  Container,
  Badge,
} from "reactstrap";
import "./styles.css";

import { appIsBlocked, appIsDisabled } from "../utils/UserApp";

export const CardContainer = ({ apps, handleClick }) => {
  return (
    <Container>
      <Row>
        {apps.map((app) => (
          <CardIten
            key={app.node.uuid}
            {...app.node}
            onClickHandler={handleClick}
          />
        ))}
      </Row>
    </Container>
  );
};

export const CardIten = ({ uuid, nome, descricao, idsituacao }) => {
  return (
    <Card
      className={
        appIsBlocked(idsituacao)
          ? "app-card mb-4 col-sm-6 col-lg-4 disabled-link"
          : "app-card mb-4 col-sm-6 col-lg-4"
      }
    >
      {appIsBlocked(idsituacao) && (
        <div>
          <i className="fas fa-lock"></i>
          <Badge className="badge-warning">
            Aplicativo bloqueado por questões de segurança
          </Badge>
        </div>
      )}
      {appIsDisabled(idsituacao) && (
        <div>
          <i className="fas fa-ban"></i>
          <Badge className="badge-secondary">
            Aplicativo desativado
          </Badge>
        </div>
      )}

      <CardBody>
        <Link to={`/user/app/${uuid}`} style={{ color: "inherit" }}>
          <Row>
            <Col className="col-auto">
              <div className="icon">
                <i className="ni ni-app" />
              </div>
            </Col>
            <div>
              <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                {nome}
              </CardTitle>
              <span className="h4 text-muted mb-0">
                {descricao.length > 20
                  ? descricao.substring(0, 20) + "..."
                  : descricao}
              </span>
              {/* <p className="h4 text-muted mb-0">{url}</p> */}
              <p className="h4 text-muted mb-0">{status}</p>
            </div>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            {/* <span className="text-success mr-2">
              <i className="fa fa-arrow-up" /> 3.48%
            </span> */}
            <span className="text-nowrap">
              <strong>id:</strong> {uuid}
            </span>
          </p>
          {/* <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap" onClick={onClick}>Detalhes</span>
          </p> */}
        </Link>
      </CardBody>
    </Card>
  );
};

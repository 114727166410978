import React, { useState, useEffect } from "react";
import { Form, Row, Col, FormGroup, Input, Button } from "reactstrap";
import ToggleButton from "components/Common/ToggleButton";
import { appIsDisabled } from "../../utils/UserApp";

const AppDetailForm = ({
  uuid,
  nome,
  descricao,
  url,
  client_id,
  client_key,
  idsituacao,
  handleUpdate,
  handleRemove,
  handleToggle,
}) => {
  const initialFormState = {
    nome,
    descricao,
    url,
  };
  const [formData, setFormData] = useState(initialFormState);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (
      (formData.nome === nome &&
        formData.url === url &&
        formData.descricao === descricao) ||
      formData.nome === "" ||
      formData.url === "" ||
      formData.descricao === ""
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    //send("FETCH");
  }, [formData.nome, formData.descricao, formData.url]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdate(uuid, formData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* <h6 className="heading-small text-muted mb-4">
          Dados do aplicativo
          </h6> */}
      <div className="pl-lg-4">
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Nome do aplicativo
              </label>
              <Input
                className="form-control-alternative"
                defaultValue={formData.nome}
                id="input-username"
                placeholder="Nome do aplicativo"
                name="nome"
                type="text"
                onChange={(evt) =>
                  setFormData({
                    ...formData,
                    [evt.target.name]: evt.target.value,
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-email">
                url
              </label>
              <Input
                className="form-control-alternative"
                id="input-url"
                placeholder="Url do aplicativo"
                type="text"
                name="url"
                defaultValue={formData.url}
                onChange={(evt) =>
                  setFormData({
                    ...formData,
                    [evt.target.name]: evt.target.value,
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-email">
                app id
              </label>
              <Input
                className="form-control-alternative"
                id="input-url"
                type="text"
                name="uuid"
                defaultValue={uuid}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-client-id">
                client id
              </label>
              <Input
                className="form-control-alternative"
                defaultValue={client_id}
                id="input-client-id"
                placeholder="First name"
                type="text"
                disabled
              />
            </FormGroup>
          </Col>
          <Col lg="8">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-client-key">
                client secret
              </label>
              <Input
                className="form-control-alternative"
                defaultValue={client_key}
                id="input-client-key"
                placeholder="Last name"
                type="text"
                disabled
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-last-name">
                Descrição
              </label>
              <Input
                className="form-control-alternative"
                placeholder="A few words about you ..."
                id="input-descricao"
                name="descricao"
                rows="4"
                defaultValue={formData.descricao}
                type="textarea"
                onChange={(evt) =>
                  setFormData({
                    ...formData,
                    [evt.target.name]: evt.target.value,
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col>
            <FormGroup>
              <div>
                <ToggleButton
                  className="float-right"
                  action={() => handleToggle(uuid, !appIsDisabled(idsituacao))}
                  checked={!appIsDisabled(idsituacao)}
                />
                <label className="form-control-label">
                  {!appIsDisabled(idsituacao)
                    ? "Desativar aplicativo"
                    : "Ativar Aplicativo"}
                </label>
                <div className="text text-muted">
                  {!appIsDisabled(idsituacao)
                    ? "Você pode desativar esse aplicativo caso queira, o aplicativo ficará sem acesso aos recursos da UFVJM."
                    : "Você pode ativar o aplicativo para continuar utilizando os recursos da UFVJM."}
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>

      {/* Address */}
      <Col>
        <div>
          <Button
            color="success"
            size="md"
            type="submit"
            disabled={isDisabled}
            outline
          >
            Atualizar
          </Button>
          <Button
            className="float-right"
            size="md"
            color="danger"
            type="button"
            onClick={() => handleRemove(true)}
            outline
          >
            Remover Aplicativo
          </Button>
        </div>
      </Col>
      <hr className="my-4" />
    </Form>
  );
};

export default AppDetailForm;

import React, { useState, useEffect } from "react";
import { getProfileData } from "services/Auth";

const vinculoReducer = (vincs, current) => {
  const { listaVinculos } = current;
  if (!listaVinculos) throw new Error("Invalid User");
  if (listaVinculos.length > 0) vincs.push(current.tipoVinculo);
  return vincs;
};

const isUserAdmin = (vinculos) => {
  return vinculos.includes("ADMIN");
};

export const getProfile = async (appTokenUsed, userTokenUsed) => {
  const result = await getProfileData(appTokenUsed, userTokenUsed);
  const { me } = result.data;
  const { vinculos } = me;
  const vinculoTypes = vinculos.reduce(vinculoReducer, []);
  const isAdmin = isUserAdmin(vinculoTypes);
  return { me, isAdmin };
};

export const Profile = {
  PROFILE_KEY_NAME: "profile",

  save(data) {
    localStorage.setItem(this.PROFILE_KEY_NAME, JSON.stringify(data));
    return true;
  },

  get() {
    return localStorage.getItem(this.PROFILE_KEY_NAME);
  },

  destroy() {
    localStorage.removeItem(this.PROFILE_KEY_NAME);
    return true;
  },
};

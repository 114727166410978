import React, { useState } from "react";
import { useMachine } from "@xstate/react";
import AppsMachine from "./apps-machine";
import AppForm from "./AppForm";
import { BasicPage } from "../../../components/Common/BasicPage";
import { CardContainer } from "./AppCard";
import { NewAppModal } from "./AppModal";
import { ContainerWrapper } from "../../../components/Common/ContainerWrapper";
import { getApps, createApp } from "../../../services/Auth";
import { AppsNotFoundComponent } from "./AppsNotFound";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../../context/Auth";
import { useAlertContext } from "../../../context/Alert";

export const AppsPage = () => {
  const { appToken, userToken, profile } = useAuth();
  const { idpessoa } = profile;
  const { setAlertMessage } = useAlertContext();

  const history = useHistory();

  const [current, send] = useMachine(AppsMachine, {
    services: {
      getApps: () => getApps(appToken, userToken, idpessoa),
    },
  });

  const handleRedirect = (path) => {
    history.push(path);
  };

  const handleCreate = (data) => {
    const dataSend = data;
    dataSend.idpessoa = idpessoa;
    createApp(appToken, userToken, dataSend)
      .then((response) => response.data)
      .then((data) => {
        const { nome, uuid } = data.sistemasCreateAplicativo;
        setAlertMessage(`Aplicativo ${nome} cadastrado com sucesso!`);
        handleRedirect(`/user/app/${uuid}`);
      });
  };

  const handleAppClick = (event, args) => {
    send(event, args);
  };

  let view = null;
  const { apps } = current.context;

  if (current.matches("list")) {
    view = (
      <ContainerWrapper
        handleCreate={() => send("CREATE")}
        title="Minhas Aplicações"
      >
        {apps.length > 0 ? (
          <CardContainer apps={apps} handleClick={handleAppClick} />
        ) : (
          <AppsNotFoundComponent />
        )}
      </ContainerWrapper>
    );
  }

  if (current.matches("create")) {
    view = (
      <React.Fragment>
        <NewAppModal isOpen={true} setModalShow={() => send("CANCEL")}>
          <AppForm
            handleCancel={() => send("CANCEL")}
            handleCreate={handleCreate}
          />
        </NewAppModal>
        <ContainerWrapper
          handleCreate={() => undefined}
          title="Minhas Aplicações"
        >
          <CardContainer apps={apps} handleClick={handleAppClick} />
        </ContainerWrapper>
      </React.Fragment>
    );
  }

  return <BasicPage>{view}</BasicPage>;
};

export default AppsPage;

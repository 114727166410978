import React from "react";
import {
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const BLOCKED = 3;
const ACTIVE = 1;
const PENDING = 2;
const REMOVED = 4;

const APP_STATES = {
  1: "Ativo",
  2: "Pendente",
  3: "Bloqueado",
  4: "Removido",
};

const AppMapStates = (idsituacao) => {
  try {
    return APP_STATES[idsituacao];
  } catch (err) {
    return "Desconhecido";
  }
};

const ActionItem = ({ children, action }) => {
  return <DropdownItem onClick={() => action()}>{children}</DropdownItem>;
};

const AppStatusItem = ({ idsituacao }) => {
  const text = AppMapStates(idsituacao);
  const statusClassMap = { 1: "bg-success", 2: "bg-primary", 3: "bg-warning" };
  return (
    <Badge color="" className="badge-dot mr-4">
      <i className={statusClassMap[idsituacao]} />
      {text}
    </Badge>
  );
};

const TableRow = ({ app, handleReview, handleBlock, handleUnblock }) => {
  console.log("app", app);

 

  return (
    <React.Fragment>
      <tr>
        <th scope="row">
          <Media className="align-items-center">
            <a
              className="avatar rounded-circle mr-3"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <img alt="..." src={require("assets/img/theme/bootstrap.jpg")} />
            </a>
            <Media>
              <span className="mb-0 text-sm">{app.nome}</span>
            </Media>
          </Media>
        </th>
        <td>{app.client_id}</td>
        <td>
          <AppStatusItem idsituacao={app.idsituacao} />
        </td>
        <td>
          <div className="avatar-group">
            <a
              className="avatar avatar-sm"
              href="#pablo"
              id="tooltip742438047"
              onClick={(e) => e.preventDefault()}
            >
              <img
                alt="..."
                className="rounded-circle"
                src={require("assets/img/theme/team-1-800x800.jpg")}
              />
            </a>
            <UncontrolledTooltip delay={0} target="tooltip742438047">
              {app.user}
            </UncontrolledTooltip>
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <span className="mr-2">60%</span>
            <div>
              <Progress max="100" value="60" barClassName="bg-danger" />
            </div>
          </div>
        </td>
        <td className="text-right">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              color=""
              onClick={(e) => e.preventDefault()}
            >
              <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              {app.idsituacao == BLOCKED ? (
                <ActionItem action={() => handleUnblock(app)}>
                  Desbloquear
                </ActionItem>
              ) : (
                <>
                  <ActionItem action={() => handleReview(app)}>
                    Review
                  </ActionItem>
                  <ActionItem action={() => handleBlock(app)}>
                    Bloquear
                  </ActionItem>
                </>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    </React.Fragment>
  );
};

export const ApplicationsTableList = ({
  aplicativos,
  handleReview,
  handleBlock,
  handleUnblock,
}) => {
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">App</th>
          <th scope="col">Client Id</th>
          <th scope="col">Status</th>
          <th scope="col">User</th>
          <th scope="col">View Mode</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {aplicativos.map((app, idx) => (
          <TableRow
            key={idx}
            app={app.node}
            handleReview={handleReview}
            handleBlock={handleBlock}
            handleUnblock={handleUnblock}
          />
        ))}
      </tbody>
    </Table>
  );
};

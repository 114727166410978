import React, { useEffect, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table, Badge, Form, Input } from "reactstrap";
import { useParams } from "react-router-dom";
import { getAcessosAplicativo } from "../../../../services/Auth";
import { useAuth } from "../../../../context/Auth";
import {
  AdminContentContainer,
  PaginationContentContainer,
} from "../../Common/Containers";

import { reviewAcessoAplicativo } from "../../../../../src/services/Auth";
import { ModalDenyAccess } from "../../../../components/Common/Modal";

const wrapBadge = (text, color) => <Badge color={color}>{text}</Badge>;

const AccessGrid = ({ access, handleApprove, handleDeny }) => {
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Transação</th>
          <th scope="col" style={{ width: "25%" }}>
            Justificativa
          </th>
          <th scope="col">Status</th>
          <th scope="col">User</th>
          <th scope="col" style={{ textAlign: "center" }}>
            Ações
          </th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {access.map((acc, idx) => (
          <AccessRow
            key={idx}
            acc={acc}
            handleApprove={handleApprove}
            handleDeny={handleDeny}
          />
        ))}
      </tbody>
    </Table>
  );
};

const mapSituacao = (situacao) => {
  switch (situacao) {
    case 1:
      return wrapBadge("Aprovado", "success");
      break;
    case 2:
      return wrapBadge("Negado", "danger");
    case 3:
      return wrapBadge("Pendente", "warning");
    case 4:
      return wrapBadge("Removido", "secondary");
    default:
      return "Situação não reconhecida";
  }
};

const AccessRow = ({ acc, handleApprove, handleDeny }) => {
  const { idsituacao, idacessoaplicativo } = acc.node;

  return (
    <tr>
      <th>
        <div>
          {acc.node.objTransacao.transacao} - {acc.node.objTransacao.descricao}
        </div>
      </th>
      <td style={{ width: "40%" }}>
        <Input type="textarea" defaultValue={acc.node.justificativa} readOnly />
      </td>
      <td>{mapSituacao(idsituacao)}</td>
      <td>acasasfas</td>
      {idsituacao === 3 && (
        <td>
          <div>
            <Button
              color="primary"
              outline
              size="sm"
              onClick={() => handleApprove(idacessoaplicativo)}
            >
              Aprovar
            </Button>
            <Button
              color="warning"
              outline
              size="sm"
              onClick={() => handleDeny(acc.node.idacessoaplicativo)}
            >
              Reprovar
            </Button>
          </div>
        </td>
      )}
    </tr>
  );
};

const reviewStateReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_ACCESS":
      return { ...state, fetching: true };

    case "LIST":
      return {
        ...state,
        view: action.type,
        access: action.payload,
      };
    case "SHOW_DENY_MODAL":
      return {
        ...state,
        modalDeny: true,
        idacesso: action.payload,
      };
    case "HIDE_MODAL_DENY":
      return { ...state, modalDeny: !state.modalDeny };
    default:
      return state;
  }
};

const initialState = {
  view: "FETCH_ACCESS",
  fetching: false,
  access: [],
  idacesso: null,
  modalDeny: false,
};

export const ReviewAccess = () => {
  const [state, dispatch] = useReducer(reviewStateReducer, initialState);
  const { id } = useParams();
  const { appToken, userToken, profile } = useAuth();
  const { idpessoa } = profile;
  const history = useHistory();
  const descriptionDenny =
    "Você deve informar o motivo da reprovação do acesso. Uma vez reprovado o aplicativo nao terá acesso a essa funcionalidade.";

  const handleDispatch = (type, payload) => {
    dispatch({ type, payload });
  };

  const fetchAccess = () => {
    const result = getAcessosAplicativo(appToken, userToken, null, id);
    result
      .then((response) => response.data)
      .then((data) => {
        const { edges } = data.sistemasAcessosAplicativo;
        handleDispatch("LIST", edges);
      });
  };

  useEffect(() => {
    handleDispatch("FETCH_ACCESS", {});
    fetchAccess();
  }, []);

  const handleApprove = (idacessoaplicativo) => {
    const APPROVED = 1;
    const data = {
      idpessoaavaliacao: idpessoa,
      idsituacao: APPROVED,
    };
    reviewAcessoAplicativo(appToken, userToken, idacessoaplicativo, data)
      .then((response) => response.data)
      .then((data) => {
        console.log("show success message");
        fetchAccess();
      })
      .catch((err) => {
        console.log("show error message");
        setAlertMessage(err);
      });
  };

  const handleDenyModal = (idAplicativo) => {
    handleDispatch("SHOW_DENY_MODAL", idAplicativo);
  };

  const handleDeny = (message) => {
    const DENY = 2;
    const data = {
      idpessoaavaliacao: idpessoa,
      idsituacao: DENY,
      motivo: message,
    };
    reviewAcessoAplicativo(appToken, userToken, state.idacesso, data)
      .then((response) => response.data)
      .then((data) => {
        console.log("show success message");
        fetchAccess();
        handleDispatch("HIDE_MODAL_DENY");
      })
      .catch((err) => {
        console.log("show error message");
        setAlertMessage(err);
      });
  };

  const handleRedirect = (redirectUrl) => {
    history.push(redirectUrl);
  };

  return (
    <AdminContentContainer>
      {state.view === "LIST" && (
        <PaginationContentContainer
          title="Acessos do Aplicativo"
          handleBack={() => handleRedirect("/admin/apps/review")}
        >
          <ModalDenyAccess
            modal={state.modalDeny}
            toggle={() => handleDispatch("HIDE_MODAL_DENY")}
            handleOk={handleDeny}
            description={descriptionDenny}
            btnTitle="Reprovar"
          />
          {state.access.length >= 1 ? (
            <AccessGrid
              access={state.access}
              handleApprove={handleApprove}
              handleDeny={handleDenyModal}
            />
          ) : (
            <p className="text text-center"> Nenhum acesso para aprovação</p>
          )}
        </PaginationContentContainer>
      )}
    </AdminContentContainer>
  );
};

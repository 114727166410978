import React, { createContext, useContext, useState, useEffect } from "react";
import { loginApp } from "../../services/Auth";

import { appId, appKey } from "../../Config";

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const handlerAppLogin = (response, setToken) => {
  const { token } = response.data.loginApp;
  setToken(token);
};

const handlerAppLoginError = (response) => {
  const { graphQLErrors } = response;
  graphQLErrors.forEach((error) => {
    console.log("ERROR: ", error.message);
  });
};

export const AuthProvider = ({ children }) => {
  const profileDataLocal = JSON.parse(localStorage.getItem("profile"));
  const appTokenLocal = localStorage.getItem("appToken");
  const userTokenLocal = localStorage.getItem("userToken");

  const [userToken, setUserToken] = useState(userTokenLocal);
  const [profile, setProfile] = useState(profileDataLocal);
  const [appToken, setAppToken] = useState(appTokenLocal);

  const setUserTokenPersistent = (userToken) => {
    localStorage.setItem("userToken", userToken);
    setUserToken(userToken);
  };

  const setAppTokenPersistent = (appToken) => {
    localStorage.setItem("appToken", appToken);
    setAppToken(appToken);
  };

  const revokeUserAuth = () => {
    localStorage.removeItem("userToken");
    setUserToken("");
    localStorage.removeItem("profile");
    setProfile("");
  };

  const setProfileDataPersistent = (profileData) => {
    localStorage.setItem("profile", JSON.stringify(profileData));
    setProfile(profileData);
  };

  if (!appTokenLocal || appTokenLocal == "undefined") {
    loginApp(appId, appKey)
      .then((response) => handlerAppLogin(response, setAppTokenPersistent))
      .catch(handlerAppLoginError);
  }

  return (
    <AuthContext.Provider
      value={{
        appToken,
        userToken,
        setUserToken: setUserTokenPersistent,
        setAppToken: setAppTokenPersistent,
        profile,
        setProfileData: setProfileDataPersistent,
        revokeUserAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";


const FormMachine = Machine({
    id: "FormMachine",
    initial: "editing",
    context: {
        key: {},
        fields: {},
        errorMsg: ''
    },
    states: {
        editing: {
            on: {
                VALIDATE: "validating"
            }
        },
        validating: {
            invoke: {
                src: "validate",
                onDone: {
                    target: 'submitting'                    
                },
                onError:{
                    target: 'error',
                    actions: assign({ errorMsg: (context, event) => event.data})
                }
            }
        },
        submitting: {
            invoke: {
                src: "submit",
                onDone: {
                    target: "finished",
                    actions: assign({
                        key: (context, event) => {
                            return event.data;
                        }
                    })
                },
                onError: {
                    target: 'error'
                }
            }
        },
        error: {
            on:{
                VALIDATE: "validating" 
            }
        },
        finished: {
            type: 'final',
            invoke: {
                src: 'finished'
            }
        }
    }
});


const useFormMachine = options => useMachine(FormMachine, options);
export default useFormMachine;
import React from "react";

const ToggleButton = ({ className, action, checked }) => {
  return (
    <label className={`custom-toggle ${className}`}>
      <input defaultChecked={checked} type="checkbox" onClick={() => action()} />
      <span className="custom-toggle-slider rounded-circle" />
    </label>
  );
};

export default ToggleButton;

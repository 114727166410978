/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import AdminSideBar from "components/Sidebar/Admin";
import { useAuth } from "../context/Auth";
import { getProfile } from "services/Profile";

import routes from "../routes.js";
console.log("Admin template", routes);

const Admin = (props) => {
  const { userToken, setProfileData, appToken } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);

  const fetchProfile = async () => {
    const result = await getProfile(appToken, userToken);
    const { isAdmin } = result;
    setIsAdmin(isAdmin);
  };

  useEffect(() => {
    fetchProfile();
  });

  console.log("isAdmin", isAdmin);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.sub) {
          return prop.sub.map((item, idx) => {
            return (
              <Route
                path={item.layout + item.path}
                component={item.component}
                key={idx}
              />
            );
          });
        }
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <div>
      {isAdmin ? (
        <React.Fragment>
          <AdminSideBar
            {...props}
            routes={routes.filter(
              (route) => route.layout === "/admin" && !route.hidden
            )}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("assets/img/brand/computer.png"),
              imgAlt: "...",
            }}
          />
          <div className="main-content">
            <AdminNavbar
              {...props}
              brandText={getBrandText(props.location.pathname)}
            />
            <Switch>{getRoutes(routes)}</Switch>
            <Container fluid>
              <AdminFooter />
            </Container>
          </div>
        </React.Fragment>
      ) : (
        <div>not allowed</div>
      )}
    </div>
  );
};

export default Admin;
